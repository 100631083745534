import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_API } from './constants';
import { Helmet } from 'react-helmet'

export class Rules extends Component {

    componentDidMount() {
        this.logPageHit();
    }

    logPageHit() {
        var pagename = 'Rules';
        var user = localStorage.getItem('mhscl-user');
        ReactGA.initialize(GOOGLE_ANALYTICS_API);
        ReactGA.pageview(pagename);
        var url = 'api/Admin/LogPageHit/' + pagename + '/' + user;
        fetch(url, { method: 'post' });
    }

    render() {
        return (
            <div style={{ maxWidth: '600px', margin: '0 auto' }}>
                <Helmet>
                    <title>Rules and Schedule for the Minnesota High School Chess League 2023-24</title>
                </Helmet>
                <h1>Rules</h1>
                <p className="blue-text">
                    The Minnesota Online High School Chess League is an organization in which teams from around the state compete online in team matches;
                    it is intended to promote both competition and learning in chess among high schoolers.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>1. Players on teams: </span>
                    Teams will consist of four players. Each team will consist of players who play on Boards 1, 2, 3, and 4. Each team's Board 1 will play the opposing Board 1,
                    each Board 2 will play a Board 2, and so on.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>2. Teams from schools: </span>
                    If a school has enough players, it may elect to have more than one team participate in the league. The school must designate its
                    teams "1", "2", "3", etc., from the strongest to the weakest teams. Coaches may also decide to make teams of approximately equal
                    strength, still designated "1", "2", "3", etc. The Director will try to prevent teams from the same school from playing each other
                    but this will not be guaranteed.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>3. Board order: </span>
                    Players must play in rating order from Board 1 to Board 4. Unrated players may play on any board. Exceptions to the rule of rating order
                    can be made if there is a reasonable justification presented to the Director. For example, if a player is provisionally or otherwise
                    inaccurately rated, this player can be moved to a different spot in the lineup. Or, if players are similarly rated and the coach wishes
                    to switch their order at the beginning of the season, this is permitted; however, once the board order has been set, the board order should
                    remain constant throughout the season.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>4. Substitutions: </span>
                    The Director realizes that there are ways in which players can be switched between teams from the same school to gain an improper
                    competitive advantage; however, the Director also realizes that substitutions between teams must be allowed to prevent teams from
                    forfeiting games. The following rule is designed to bridge the gap between these two issues. After a coach has submitted regular season
                    core lineups to the Director (see rule 15), players who have been listed as core players for team 1 should not play for any other team.
                    Players who have been listed as core players for teams lower than 1 may be placed on the higher teams, but usually only if a member of the
                    higher team is absent from the match. Players who have been listed as alternates may substitute for any team, but usually only if the core
                    players they are replacing are absent from the match. This rule will be enforced on a case-by-case basis, and only enforced if the teams are
                    improperly gaining a competitive advantage. If disputes arise, the Director should be contacted and the disputes will be investigated.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>5. Match scores: </span>
                    If a team's four players score at least 2.5 points, that team wins the match and receives one match point and game points equivalent
                    to their point score. If a team's four players score 2 points, that team ties the match and receives half a match point and two game
                    points. In case of a double forfeit on one or two boards, the scoring rule above still applies.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>6. The season: </span>
                    The season will consist of one preseason round and nine regular-season rounds.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>7. The regular season: </span>
                    The nine regular-season matches will be paired by the Swiss pairing system, using match points as the metric for pairing. Pairings within
                    score groups are random, and teams from the same school will not be paired unless absolutely necessary.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>8. Tiebreakers: </span>
                    Teams' standings in the regular season will use the following set of tiebreakers for the purpose of seeding during the playoffs. The first
                    tiebreaker is match points, the second is Sonneborn-Berger score with each opponent's season match point score multiplied by the
                    individual match score, the third is game points, and the fourth is modified median. In the case of a tie, a blitz playoff or coin
                    flip will be used to determine award order.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>9. Time controls: </span>
                    The time control for all games will be game in 30 minutes with a 3 second increment.                
                </p><p className="blue-text">
                    <span className='font-weight-bold'>10. Schedule: </span>
                    <ul className="blue-text">
                        <li className="blue-text">10/27/2024 (Preseason)</li>
                        <li className="blue-text">11/03/2023 (Round 1)</li>
                        <li className="blue-text">11/17/2023 (Round 2)</li>
                        <li className="blue-text">12/01/2023 (Round 3)</li>
                        <li className="blue-text">12/15/2023 (Round 4)</li>
                        <li className="blue-text">01/05/2024 (Round 5)</li>
                        <li className="blue-text">01/19/2024 (Round 6)</li>
                        <li className="blue-text">02/02/2024 (Round 7)</li>
                        <li className="blue-text">02/16/2024 (Round 8)</li>
                        <li className="blue-text">03/01/2024 (Round 9)</li>
                    </ul>
                </p><p className="blue-text">
                    <span className='font-weight-bold'>11. Cost: </span>
                    The cost to enter the league will be $50 for the first team from a school, $25 for each additional team. 
                </p><p className="blue-text">
                    <span className='font-weight-bold'>12. Submission of teams to Director: </span>
                    School coaches are required to notify the league Director by email of how many teams they will enter by the Friday before the
                    first match. Coaches should provide a list of four core players for each team who they believe will generally be the players
                    playing for that team. These four players' average rating will be used throughout the season as that team's rating, absent
                    unexpected circumstances. If coaches wish to add teams after the first match, they may; these teams must be submitted by the
                    Friday before the match. These additional teams will be given up to three half-point byes for the missed round(s) (see rule 16).
                </p><p className="blue-text">
                    <span className='font-weight-bold'>13. Submission of lineup to Director: </span>
                    School coaches are required to provide a list of players who will be playing each match to the Director by email by each
                    Thursday before the Friday match at 5:00pm. Last-minute changes until 12:00pm on Friday to these lineups are permitted if
                    an emergency prevents a player from attending a match, but a continued pattern of these last-minute changes may result in
                    the Director disallowing the change and instead choose to forfeit the player(s). Pairings will be published and sent out by
                    the Director on Friday afternoon.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>14. Match venue: </span>
                    Matches will be played on the website lichess.org. Each player must create an account with their school name and last name;
                    the format will be �Schoolname-Lastname�; e.g. �Blake-Wahl�. School names may be abbreviated to allow for more space for last
                    names. All of these restrictions are put in place to help players and coaches find correct accounts.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>15. Forfeits: </span>
                    If games are not started by 3:45pm, or if players play the wrong opponents, forfeits may be administered at the discretion
                    of the Director. Such infractions will be reviewed on a case-by-case basis. If a player does not show up when he or she is
                    scheduled to play, the remaining players must be moved up boards to fill higher-board holes and to forfeit only the last board.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>16. Half-point byes: </span>
                    Teams may request up to three team half-point byes for any regular-season rounds. These byes will apply to the whole team;
                    the team will receive half a match point and two game points for each bye.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>17. Player eligibility: </span>
                    Players are only eligible to play for schools which they are eligible to play for in USCF team tournaments. In special
                    cases, when a school is unable to regularly field four players, home-schooled players or players from middle or elementary
                    schools who live in the correct school district are allowed to play for the team.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>18. Alternates: </span>
                    If players are present and wish to play, but all teams from their school are full, they are designated as alternates. The
                    coach should submit their names along with their team rosters and the Director will attempt to pair them for the round
                    against other alternates.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>19. Awards: </span>
                    There will be trophies given to the top three teams, to multiple teams with ratings below various levels, and to the best
                    three scoring players on each of Board 1, 2, 3, and 4. (A player is counted as having played on the lowest board they ever
                    played on.) There will also be awards for at least the best three scoring players who at least once played as an alternate.
                    (Players who have played as alternates are not eligible for the other board prizes.) Additionally, certificates will be granted
                    to players who played all of the matches their school participated in.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>20. Fair play: </span>
                    This league is just for fun and it is expected that no players will attempt to cheat. However, coaches must carefully
                    monitor students to ensure that there is no communication with chess engines or other students while games are going on.
                    Students should not watch other students' ongoing games, but if they do, coaches must make sure that no communication,
                    including facial expressions and noises, occurs. Penalties for cheating may include suspensions of players, forfeiture of
                    games, and in egregious cases, banning of teams.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>21. Uncontrollable circumstances: </span>
                    So-called �mouse-slips�, when a player moves a piece to an unintended square, will universally stand. No take backs are
                    allowed. Internet and computer problems are the responsibility of the player, coach, and school to resolve, and no matches
                    involving Internet or computer problems will be replayed.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>22. Application of rules: </span>
                    All players in all League games agree to be bound by these rules.
                </p><p className="blue-text">
                    <span className='font-weight-bold'>23. Other disputes: </span>
                    All disputes not covered here will be submitted to and reviewed by the Director, who reserves
                    all rights to act equitably and in the best interest of the League.
                </p>  <br/><br/> </div>
               
        );
    }
}
